import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMedia } from '../hook';
import { lazy } from '@loadable/component';

// Components
import SEO from '../components/seo';
import Footer from '../components/layout/footer';
import WhatWeDoLoading from '../components/whatWeDo/loading';

// Image
import InfoGraphicDesktop from '../images/what-we-do.webp';
import InfoGraphicMobile from '../images/what-we-do-mobile2.webp';

// utils
import withTranslate from '../utils/withTransWrapper';

const WhatWeDo = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const image = useRef();
  const isDesktop = useMedia('(min-width: 768px)');

  const handleImageLoaded = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      handleImageLoaded();
    }
  }, []);

  return (
    <>
      <Wrapper>
        <SEO title="WhatWeDo" />
        <DetailPage>
          {!isLoaded && (
            <>
              <LoadingContainer>
                <LoadingWrapper>
                  <WhatWeDoLoading />
                </LoadingWrapper>
              </LoadingContainer>
              {/* <ImageWrapperLoading>
                <img src={Test} alt="loading" quality={70} layout="FULL_WIDTH" />
              </ImageWrapperLoading> */}
            </>
          )}
          {isDesktop ? (
            <ImageWrapper isLoaded={isLoaded}>
              <img
                width={'100%'}
                height={'100%'}
                ref={image}
                onLoad={handleImageLoaded}
                src={InfoGraphicDesktop}
                loading="lazy"
                alt="infographic"
              />
            </ImageWrapper>
          ) : (
            <ImageWrapper isLoaded={isLoaded}>
              <img
                width={'100%'}
                height={'100%'}
                ref={image}
                onLoad={handleImageLoaded}
                src={InfoGraphicMobile}
                loading="lazy"
                alt="infographic"
              />
            </ImageWrapper>
          )}
        </DetailPage>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const DetailPage = styled.div`
  min-height: 60vh;
  position: relative;
  margin: 0;
  padding-top: 90px;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 64px;
  height: 64px;
`;

const ImageWrapper = styled.div`
  width: 100vw;
  height: auto;
  visibility: ${(props) => (props.isLoaded ? `visible` : `hidden`)};
`;

export default withTranslate(WhatWeDo, true);
