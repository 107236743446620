import { useLottie } from 'lottie-react';
import loadingLottie from './loading.json';

const WhatWeDoLoading = () => {
  const options = {
    animationData: loadingLottie,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);
  return View;
};
export default WhatWeDoLoading;
